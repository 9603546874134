import { httpRequests } from "../../../helpers/HttpRequests"

export function roomsServices() {
    const { getRequest, postRequest, putRequest, httpErrors } = httpRequests()
    const errors = httpErrors

    function getFloorsWithoutPagination() {
        return getRequest('/web/floors/without_pagination')
    }

    function addSingleRoomService(room) {
        return postRequest('/web/rooms/add/single', room)
    }

    function addMultipleRoomsService(rooms) {
        return postRequest('/web/rooms/add/multiple', rooms)
    }

    function editRoomService(roomId) {
        return getRequest(`/web/rooms/edit/${roomId}`)
    }

    function updateRoomService(roomId, room) {
        return putRequest(`/web/rooms/update/${roomId}`, room)
    }

    function qrMakerService(roomId) {
        return getRequest(`/web/rooms/qr_maker/${roomId}`)
    }

    function qrCodesSheet() {
        return getRequest(`/web/rooms/qr_sheet`, { 'responseType': 'blob' })
    }

    return {
        addSingleRoomService,
        addMultipleRoomsService,
        editRoomService,
        updateRoomService,
        getFloorsWithoutPagination,
        qrMakerService,
        qrCodesSheet,
        errors
    }
}