<template>
    <div :class="['notification', colorClass, shadowClass]" v-show="showNotification">
        <div class="notification__title">
            {{ titleNotification }}

            <img src="@/assets/icons/error-notification-icon.svg" alt="" v-if="errorNotification">
            <img src="@/assets/icons/success-notification-icon.svg" alt="" v-if="successNotification">
            <img src="@/assets/icons/info-notification-icon.svg" alt="" v-if="infoNotification">
        </div>
        <div class="notification__body">
            {{ textNotification }}
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

let successNotification = ref(false);
let errorNotification = ref(false);
let infoNotification = ref(false);
let showNotification = ref(false);
let titleNotification = ref("");
let textNotification = ref("");
let colorClass = "";
let shadowClass = "";
let timeOutId = "";

// * Send notification function
function sendNotification(typeNotification, title, text) {
    if (typeNotification == 'error') {
        errorNotification.value = true;
        colorClass = "notification--error";
        shadowClass = "notification--error-shadow"
    }

    if (typeNotification == 'success') {
        successNotification.value = true;
        colorClass = "notification--success";
        shadowClass = "notification--success-shadow"
    }

    if (typeNotification == 'info') {
        infoNotification.value = true;
        colorClass = "notification--info";
        shadowClass = "notification--info-shadow"
    }

    titleNotification.value = title;
    textNotification.value = text;
    showNotification.value = true;
}

// * Hide notification function
function hideNotification(typeNotification) {
    if (timeOutId) clearTimeout(timeOutId);

    timeOutId = setTimeout(() => {
        titleNotification.value = "";
        textNotification.value = "";
        typeNotification.value = false;
        showNotification.value = false;
    }, 3000);
}

// * Watchers
watch(successNotification, () => { hideNotification(successNotification) });
watch(errorNotification, () => { hideNotification(errorNotification) });
watch(infoNotification, () => { hideNotification(infoNotification) });

// * Expose values 
defineExpose({ sendNotification });
</script>