<template>
    <form class="form" @submit.prevent="updateRoom">
        <div class="form__inputs-content">
            <div class="form__input-form">
                <label class="form__input-label form--required">Room Name</label>
                <input type="text" placeholder="Example 1 Or 1B" class="form__input" v-model="room.name">

                <div class="form__input-error" v-if="errors.fields.name">
                    {{ errors.fields.name[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Floor</label>
                <select class="form__input" v-model="room.floorId">
                    <option value="">Select Floor Location</option>
                    <option :value="floor.id" v-for="floor in floors" :key="floor.id">
                        {{ floor.name }} {{ floor.prefix }}
                    </option>
                </select>

                <div class="form__input-error" v-if="errors.fields.floorId">
                    {{ errors.fields.floorId[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Average Time To Service</label>
                <input type="text" placeholder="Duration of service in minutes" class="form__input"
                    v-model="room.averageTime">

                <div class="form__input-error" v-if="errors.fields.averageTime">
                    {{ errors.fields.averageTime[0] }}
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button class="button button--success" v-if="!buttonLoader">
                <img src="@/assets/icons/vuesax/linear/refresh-2.svg" alt="">
                Update Room
            </button>

            <ButtonTextLoader button-class="button button--success button-text-loader--content-width"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />
        </div>
    </form>

    <Notifications ref="notification" />
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { roomsServices } from '../Services/RoomsServices'
import Notifications from '../../../common/Notifications.vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useRouter } from 'vue-router'
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'

const props = defineProps(['roomId'])
const { editRoomService, updateRoomService, getFloorsWithoutPagination, errors } = roomsServices()
const notification = ref(null);
const { sweetSuccess } = sweetAlerts()
const router = useRouter()
const buttonLoader = ref(false)
const room = reactive({ name: '', averageTime: '', floorId: '' })
const floors = ref([])

onMounted(() => {
    getRoom()
    getFloors()
})

async function getRoom() {
    const response = await editRoomService(props.roomId)

    if (response.hasErrors) return console.error(`Error on get room ${response.hasErrors}`)

    room.name = response.data.name
    room.floorId = response.data.floor_id
    room.averageTime = response.data.average_time
}

async function updateRoom() {
    // * Show button loader
    buttonLoader.value = true

    const response = await updateRoomService(props.roomId, room)

    if (response.hasErrors) {
        buttonLoader.value = false
        notification.value.sendNotification('error', 'Error', response.message)

        return console.error(`Error on update room ${response.hasErrors}`)
    }

    router.push('/hotel_setup/rooms')

    return sweetSuccess(response.data.message)
}

async function getFloors() {
    const response = await getFloorsWithoutPagination()

    if (response.hasErrors) return console.error(`Error on get floors ${response.hasErrors}`)

    floors.value = response.data
}
</script>